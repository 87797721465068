const isEmpty = (thing) => {
	if (Array.isArray(thing) || typeof thing === 'string') {
		return thing.length === 0;
	}

	if (typeof thing === 'object') {
		// Info: https://stackoverflow.com/a/32108184/2361310
		return Object.keys(thing).length === 0 && thing.constructor === Object;
	}

	// If nothing matches, return null, to be able to test this function
	return null;
};

export default isEmpty;
