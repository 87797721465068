import { getCurrentBreakPoint } from '@/helpers/breakpoint';

export const setLocationsListVisibility = ({ commit }, newState) => {
	commit('SET_LOCATIONS_LIST_VISIBILITY', newState);
};

export const toggleLocationsList = ({ commit, state }, forceLocationsList) => {
	// We want to be extra sure that only strings or booleans can be inserted for manual overriding
	if (typeof forceLocationsList === 'string' || typeof forceLocationsList === 'boolean') {
		commit('SET_LOCATIONS_LIST_VISIBILITY', forceLocationsList);
	} else {
		commit('SET_LOCATIONS_LIST_VISIBILITY', !state.locationsList.visible);
	}
};

export const setFiltersVisibility = ({ commit }, newState) => {
	commit('SET_FILTERS_VISIBILITY', newState);
};

export const toggleFilters = ({ commit, state }) => {
	commit('SET_FILTERS_VISIBILITY', !state.filters.visible);
};

export const saveFilters = ({ commit }, newState) => {
	commit('SAVE_FILTERS', newState);
};

export const savePreviousRadius = ({ commit }, newState) => {
	commit('SAVE_PREVIOUS_RADIUS', newState);
};

export const updateMapVisibility = ({ commit }) => {
	const mapShouldShow = ['none', 'tiny'].indexOf(getCurrentBreakPoint()) === -1;

	commit('UPDATE_MAP_VISIBILITY', mapShouldShow);
};
