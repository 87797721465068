class OrderService {
	/**
	 * Returns a re-ordered array, based on a given key
	 * @param {array} array the array to return with a new order
	 * @param {string} key the key you want to order the array on
	 * @param {boolean} ascending set to true when ordering ascending
	 *
	 * @returns {array} The re-ordered array
	 */
	orderItemsByKey (array, key, ascending) {
		return array.sort((a, b) => {
			if (a[key] === null) {
				return 1;
			}
			if (b[key] === null) {
				return -1;
			}
			if (ascending === true) {
				// eslint-disable-next-line
				return (a[key] > b[key]) ? 1 : (a[key] < b[key]) ? -1 : 0;
			}
			// eslint-disable-next-line
			return (a[key] > b[key]) ? -1 : (a[key] < b[key]) ? 1 : 0;
		});
	}
}

const orderService = new OrderService();

export default orderService;
