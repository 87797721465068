export function animateElementScroll (elem, unit, from, to, time) {
	const start = new Date().getTime();

	const timer = setInterval(() => {
		const step = Math.min(1, (new Date().getTime() - start) / time);

		// eslint-disable-next-line
		elem['scrollTop'] = (from + step * (to - from)) + unit;

		if (step === 1) {
			clearInterval(timer);
		}
	}, 25);

	elem.style.scrollTop = from + unit;
}

export function scrollToElement (element, offset) {
	if (!element) {
		return;
	}

	const app = document.documentElement;
	const el = document.querySelector(element);

	if (!app || !el) {
		return;
	}

	const top = app.scrollTop + el.getBoundingClientRect().top + offset;

	if (Math.round(top) === Math.round(app.scrollTop)) {
		return;
	}

	app.scrollTo({
		top,
		left: 0,
		behavior: 'smooth'
	});
}
