import Vue from 'vue';
import axios from 'axios';

Vue.prototype.$http = axios;

export const API = axios.create({
	/* Get rid of port number - development. Otherwise baseURL would just be: '/' */
	baseURL: `${location.protocol}//${location.hostname}`,
	withCredentials: true
});

export default API;
