import * as actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	locations: [],
	listSorting: 'name'
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
