<template>
	<div class="location-list-wrapper" @scroll="handleScroll">
		<ul class="location-list" id="resultaten">
			<locations-list-item
				v-for="location in locations"
				:key="location.id"
				:location="location"
				:g-map-breakpoints="['small', 'medium', 'large', 'huge']">
			</locations-list-item>
		</ul>
		<!-- <a href="#" @click.prevent="loadedLimit+=20">Toon meer</a> -->
	</div>
</template>

<script>
	import LocationsListItem from '@/components/LocationsListItem.vue';

	export default {
		name: 'LocationsList',
		components: { LocationsListItem },
		data: () => ({
			initialLoadedLimit: 20,
			loadedLimit: 20,
			scrolled: false
		}),
		props: {
			locations: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			locationsLimited () {
				return this.locations.slice(0, this.loadedLimit);
			}
		},
		methods: {
			handleScroll (evt) {
				const scrolled = evt.target.scrollTop > 5;

				if (this.scrolled !== scrolled) {
					this.scrolled = scrolled;
					this.$emit('scrolledChange', scrolled);
				}
			}
		}
	};
</script>

<style lang="scss">
	$module: 'location-list-wrapper';

	.#{$module} {
		padding: 1rem;
		overflow-y: scroll;
		box-sizing: border-box;
		-webkit-overflow-scrolling: touch;
	}

	@include respond-to(small) {
		.#{$module} {
			height: 100%;
			padding: 2rem;
		}
	}

	@include respond-to(medium) {
		.#{$module} {
			width: 50rem;
		}
	}
</style>
