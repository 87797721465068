<template>
	<div :class="['Input', {
			'Input--withButton': fieldType === 'text-with-button',
			'Input--withIcon': icon,
			'Input--withText': withText
		}]">
		<input
			ref="input"
			:type="type"
			:name="name"
			:defaultValue="defaultValue ? defaultValue : null"
			:value="value"
			:readonly="readonly"
			:required="required"
			:placeholder="placeholder"
			:class="`Input__Input Input__Input--text Input__Input--${type}`"
			:min="min"
			:max="max"
			@input="onInput"
			@mouseup="onInput"
			@keydown.enter="(evt) => onSubmit(evt)"
			/>

		<div class="Input__Button" v-if="fieldType === 'text-with-button'">
			<Button :click="(evt) => onSubmit(evt)">
				<svg-icon :icon="icon" v-if="icon"></svg-icon>
				<span v-if="buttonText">{{ buttonText }}</span>
			</Button>
		</div>

		<div class="Input__Text" v-if="withText">
			{{ withText }}
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import SvgIcon from '@/components/SvgIcon';
	import Button from '@/components/Button';

	export default {
		name: 'Input',
		components: { SvgIcon, Button },
		props: {
			fieldType: {
				type: String,
				default: 'text',
				validator: (fieldType) => [
					'text',
					'text-with-button'
				].indexOf(fieldType) !== -1
			},
			readonly: {
				type: Boolean
			},
			required: {
				type: Boolean
			},
			type: {
				type: String,
				default: 'text'
			},
			name: {
				type: String
			},
			defaultValue: {
				type: String
			},
			value: {
				type: [String, Number]
			},
			icon: {
				type: String
			},
			placeholder: {
				type: String
			},
			buttonText: {
				type: String
			},
			withText: {
				type: String
			},
			min: {
				type: Number
			},
			max: {
				type: Number
			},
			submit: Function,
			change: Function
		},
		methods: {
			onInput (evt) {
				evt.preventDefault();

				Vue.nextTick(() => {
					if (this.$props.change) {
						this.$props.change(evt);
					}
				});
			},
			onSubmit (evt) {
				evt.preventDefault();

				Vue.nextTick(() => {
					if (this.$props.submit) {
						this.$props.submit(this.$refs.input);
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	$module: 'Input';

	.#{$module} {
		display: block;

		&__Input {
			color: $color-dark;
		}

		&__Input--text {
			font-size: 1.6rem;
			padding: 1em;
			border-radius: 10rem;
			border: 1px solid $color-grey-light;

			&::placeholder {
				color: $color-grey-light;
				font-size: inherit;
				font-style: italic;
				font-weight: lighter;
			}
		}

		&__Input--number {
			width: 8rem;
		}

		&__Text {
			margin-left: 1rem;
		}

		&--withIcon {
			.#{$module}__Button button {
				color: $color-grey-light;
				font-size: 2.7rem;
			}
		}

		&--withText {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;
		}

		&--withButton {
			display: flex;
			align-items: stretch;
			position: relative;
			width: 100%;

			.#{$module}__Input {
				width: 100%;
				padding-right: 5em;
			}

			.#{$module}__Button {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				display: flex;
				align-items: center;
				justify-content: center;

				button {
					display: flex;
					padding: 1.5rem 2.2rem 1.5rem 1.7rem;
					color: $color-dark;
				}

				&::before {
					position: absolute;
					top: 0.75em;
					left: 0;
					width: 1px;
					height: calc(100% - 1.5em);
					background: $color-grey-light;
					content: '';
				}
			}
		}
	}
</style>
