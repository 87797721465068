<template>
	<section class="userbar">
		<template v-if="user">
			<p class="userbar__text">Gebruiker: {{ user.email }}.</p>
			<div>
				<Button :href="settings.logoutUrl" class="button--4">Log uit</Button>
				<Button
					:href="settings.editUrl"
					target="_blank"
					rel="nofollow"
					class="button--4">Gegevens bewerken</Button>
			</div>
		</template>

		<template v-else>
			<p class="userbar__text">Ik ben professional:</p>
			<div>
				<Button :click="openLoginLink" class="button--1">Log in</Button>
				<Button
					:href="settings.registrationUrl"
					target="_blank"
					rel="nofollow"
					class="button--4">Aanmelden</Button>
			</div>
		</template>
	</section>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import Button from '@/components/Button';

	export default {
		name: 'UserBar',
		components: { Button },
		computed: {
			...mapState({
				user: (state) => state.user.user,
				settings: (state) => state.settings
			})
		},
		methods: {
			...mapActions('settings', ['openLoginLink'])
		}
	};
</script>

<style lang="scss" scoped>
	$module: 'userbar';

	.#{$module} {
		-ms-grid-row: 2;
		-ms-grid-column: 1;
		-ms-grid-column-span: 2;
		grid-area: userbar;
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		justify-content: center;
		padding: 2rem 1rem;
		background: $color-light;
		box-shadow: 0 0 2rem rgba($color-dark, 0.15);
		z-index: 10;

		&__text {
			flex: 1 0 100%;
			margin-bottom: 2rem;
			text-align: center;
		}
	}

	@include respond-to(small) {
		.#{$module} {
			align-items: center;
			justify-content: flex-start;
			padding: 0 2rem;

			&__text {
				flex: 0 0 auto;
				margin-right: 2rem;
				margin-bottom: 0;
				text-align: left;
			}
		}
	}
</style>
