<template>
	<section class="waiting-times" v-if="show">
		<p v-if="unknown">Wachttijd onbekend</p>
		<!-- Issue ZD16478 -->
		<!-- averageRegistration can also be `0`, so we use `null` here: -->
		<p v-if="averageRegistration != null">
			Wachttijd tot aanmelding: ±
			<strong>
				{{ averageRegistration | ceil }}
				<template v-if="averageRegistration === 1">week</template>
				<template v-else>weken</template>
			</strong>
		</p>

		<!-- Issue ZD17896 -->
		<!-- averageTreatment can also be `0`, so we use `null` here: -->
		<p v-if="averageTreatment != null">
			Start van behandeling gemiddeld
			{{ averageTreatment | ceil }}
			<template v-if="averageTreatment === 1">week</template>
			<template v-else>weken</template>
			na aanmelding
		</p>
	</section>
</template>

<script>
	export default {
		name: 'WaitingTimes',
		props: {
			category: String,
			unknown: {
				type: Boolean,
				default: true
			},
			averageRegistration: Number,
			averageTreatment: Number
		},
		filters: {
			ceil: (value) => Math.ceil(value)
		},
		computed: {
			show () {
				if (typeof this.category === 'undefined') {
					return false;
				}

				return this.category !== 'huisarts_en_praktijkondersteuner' &&
					this.category !== 'zorgaanbieder_somatiek';
			}
		}
	};
</script>

<style lang="scss" scoped>
	.waiting-times {
		font-size: 1.4rem;
	}
</style>
