import Vue from 'vue';

export const mutations = {
	SET_LOCATIONS_LIST_VISIBILITY (state, newState) {
		Vue.set(state.locationsList, 'visible', newState);
	},
	SET_FILTERS_VISIBILITY (state, newState) {
		Vue.set(state.filters, 'visible', newState);
	},
	UPDATE_MAP_VISIBILITY (state, newState) {
		Vue.set(state.map, 'visible', newState);
	},
	SAVE_FILTERS (state, newState) {
		Vue.set(state.filters, 'active', newState);
	},
	SAVE_PREVIOUS_RADIUS (state, newState) {
		Vue.set(state.filters, 'previousRadius', newState);
	}
};

export default mutations;
