export function getCurrentBreakPoint () {
	// Get the current breakpoint name as defined via CSS. The name of the
	// breakpoint is in de body:after element. The smallest breakpoint is
	// not actually used (min-width = 0), but is called "none" as a
	// fallback word to use in JS.
	return window.getComputedStyle(
		document.querySelector('body'), ':after'
	)
		.getPropertyValue('content')
		.replace(/["'\s]/g, '');
}

export function shouldElementRender (breakpoints) {
	return breakpoints.indexOf(getCurrentBreakPoint()) >= 0;
}
