import Vue from 'vue';
import Vuex from 'vuex';
import getQueryParameter from '@/helpers/getQueryParameter';

import locations from './modules/locations';
import userInterface from './modules/userInterface';
import user from './modules/user';
import settings from './modules/settings';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		locations,
		userInterface,
		user,
		settings
	},
	state: {
		parentDomain: getQueryParameter('domain') || window.location.origin
	},
	actions: {
		/**
		* Resizes iframe to height of the body by sending message to parent script */
		resizeIframe ({ commit, state }, fixedHeight) {
			fixedHeight = typeof fixedHeight === 'number' ? fixedHeight : document.body.offsetHeight;

			parent.postMessage({
				type: 'socialMapResize',
				height: fixedHeight
			}, state.parentDomain);
		}
	}
});

export default store;
