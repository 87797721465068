<template>
	<component
		:is="`h${type}`"
		:class="[
			'heading',
			`heading--${appearanceDefault}`,
			{ [`heading--${color}`]: color },
			classes
		]">
		<slot />
	</component>
</template>

<script>
	export default {
		name: 'Heading',
		props: {
			type: {
				default: 1,
				type: [Number, String]
			},
			classes: String,
			color: String,
			appearance: [Number, String]
		},
		computed: {
			appearanceDefault (props) {
				return props.appearance ? props.appearance : props.type;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.heading {
		font-size: 1.25em;
		font-weight: bold;
		line-height: 1;

		&--dark {
			color: $color-dark;
		}

		&--light {
			color: $color-light;
		}

		&--1 {
			font-size: 3rem;
		}

		&--2 {
			font-size: 2.5rem;
		}

		&--3 {
			font-size: 2.2rem;
		}

		&--4 {
			font-size: 1.8rem;
		}

		a,
		button {
			font-size: 0.8em;
			font-weight: normal;
			padding-left: 0.5em;
		}

		small {
			font-size: 0.7em;
			font-weight: normal;
			white-space: nowrap;
		}
	}

	@include respond-to(small) {
		.heading {
			&--1 {
				font-size: 4rem;
			}

			&--2 {
				font-size: 3.2rem;
			}

			&--3 {
				font-size: 2.8rem;
			}

			&--4 {
				font-size: 2rem;
			}
		}
	}
</style>
