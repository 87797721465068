<template>
	<component
		:is="this.element()"
		:to="to"
		:type="href ? null : type"
		:href="href"
		:rel="rel"
		:target="href ? target : null"
		:disabled="disabled"
		:class="['button', classes]"
		@click="onClick">
		<slot>Button</slot>
	</component>
</template>

<script>
	export default {
		name: 'Button',
		props: {
			type: {
				default: 'button',
				type: String
			},
			href: String,
			rel: String,
			target: String,
			disabled: {
				default: false,
				type: Boolean
			},
			preventDefault: {
				default: false,
				type: Boolean
			},
			classes: {
				type: [Object, Array, String],
				default: ''
			},
			click: Function,
			to: [Object, String]
		},
		methods: {
			element () {
				if (this.href) {
					return 'a';
				}

				if (this.to) {
					return 'router-link';
				}

				return 'button';
			},
			onClick (evt) {
				if (this.preventDefault) {
					evt.preventDefault();
				}

				if (this.click) {
					this.click(evt);
				}
			}
		}
	};
</script>

<style lang="scss">
	.button--reset {
		display: inline-block;
		padding: 0;
		border: none;
		background: none;
		color: inherit;
		cursor: pointer;
	}
</style>


<style lang="scss" scoped>
	$module: 'button';

	.#{$module} {
		display: inline-block;
		padding: 0.6em 1.6em;
		border-radius: 10rem;
		background: none;
		border: none;
		font-size: 1.6rem;
		font-weight: bold;
		text-decoration: none;
		cursor: pointer;


		+ .#{$module} {
			margin-left: 1rem;
		}

		&:active {
			transform: translateY(2px);
			box-shadow: none;
		}

		&--1 {
			@include themeColor(background, colorPrimary);
			color: $color-light;
		}

		&--2 {
			@include themeColor(background, colorSecondary);
			color: $color-light;
		}

		&--3 {
			@include themeColor(background, colorSecondary);
			background: $color-light;
			color: $color-dark;
		}

		&--4 {
			@include themeColor(color, colorPrimary);
			@include themeColor(border-color, colorPrimary);
			background: transparent;
			border-style: solid;
			border-width: 1px;
		}

		&--shadow {
			box-shadow: 0px 1px 8px rgba(0,0,0,0.5);
		}

		&--close {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;

			svg {
				// fix for alignment cross icon
				margin-bottom: 0;
			}
		}
	}

	@include respond-to(medium) {
	.#{$module} {
			font-size: 1.8rem;
		}
	}
</style>
