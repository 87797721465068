<template>
	<google-map-info-window
		:options="infoWindow.options"
		:position="infoWindow.position"
		:opened="infoWindow.opened"
		@closeclick="closeClick">
		<div class="infoWindow">
			<h3>{{ infoWindow.title }}</h3>
			<p>
				{{ infoWindow.content.address.street }}, {{ infoWindow.content.address.city | city }}
			</p>
			<p>
				Tel: <a :href="`tel:${infoWindow.content.phone}`">{{ infoWindow.content.phone }}</a>
			</p>

			<br>

			<WaitingTimes
				class="space--b2"
				:category="infoWindow.content.category"
				:unknown="infoWindow.content.waitingTimeUnknown"
				:averageRegistration="infoWindow.content.waitingTimeAverageRegistration"
				:averageTreatment="infoWindow.content.waitingTimeAverageTreatment" />

			<Button
				:to="{ name: 'detail', params: { id: infoWindow.id }}"
				classes="button--1">Lees meer
			</Button>
		</div>
	</google-map-info-window>
</template>

<script>
	import Button from '@/components/Button';
	import WaitingTimes from '@/components/WaitingTimes';
	import { capitalize } from '@/helpers/text';

	export default {
		name: 'GoogleMapsInfoWindow',
		components: { Button, WaitingTimes },
		props: {
			infoWindow: Object,
			closeClick: Function
		},
		filters: {
			city: (value) => capitalize(value)
		}
	};
</script>


<style lang="scss" scoped>
	.infoWindow {
		padding-right: 1rem;
		padding-bottom: 2rem;
		max-width: 35rem;

		h3 {
			margin: 1rem 0;
			font: inherit;
			font-size: 2rem;
			font-weight: bold;
		}

		p {
			font-size: 1.6rem;
		}

		a:not(.button) {
			text-decoration: none;
			@include themeColor(color, colorPrimary);
		}
	}
</style>
