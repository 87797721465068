import Vue from 'vue';

export const mutations = {
	SET_LOCATIONS (state, locations) {
		Vue.set(state, 'locations', locations);
	},
	SET_LIST_SORTING (state, newState) {
		Vue.set(state, 'listSorting', newState);
	}
};

export default mutations;
