import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import SvgIcon from '@/components/SvgIcon.vue';
import '@babel/polyfill';
import smoothscroll from 'smoothscroll-polyfill';

Vue.config.productionTip = false;

Vue.component('SvgIcon', SvgIcon);

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyD9UuC5k8izNql_UupYkWq8hPaKIpQ-zuo',
		v: '3.39'
	},
	installComponents: false
});

Vue.component('google-map', VueGoogleMaps.Map);
Vue.component('google-map-marker', VueGoogleMaps.Marker);
Vue.component('google-map-info-window', VueGoogleMaps.InfoWindow);
Vue.component('google-map-cluster', GmapCluster);

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');

smoothscroll.polyfill();
