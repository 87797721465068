import * as actions from './actions';
import mutations from './mutations';

const state = {
	colorPrimary: window.appConfig.color_1 || '#00788c',
	colorSecondary: window.appConfig.color_2 || '#ff7b02',
	colorTertiary: window.appConfig.color_3 || '#e3ede5',
	radius: window.appConfig.radius || 25,
	latitude: window.appConfig.latitude || 51.589814,
	longitude: window.appConfig.longitude || 4.773756,
	excludeCategories: window.appConfig.exclude_categories,
	categories: window.appConfig.categories,
	filters: window.appConfig.filters,
	logoutUrl: window.appConfig.logout_url,
	loginUrl: window.appConfig.salesforce_login_url,
	editUrl: window.appConfig.salesforce_edit_url,
	registrationUrl: window.appConfig.salesforce_registration_url
};

export default {
	namespaced: true,
	state,
	actions,
	mutations
};
