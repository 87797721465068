var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(("h" + _vm.type),{tag:"component",class:[
		'heading',
		("heading--" + _vm.appearanceDefault),
		( _obj = {}, _obj[("heading--" + _vm.color)] = _vm.color, _obj ),
		_vm.classes
	]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }