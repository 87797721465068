<template>
	<div class="app" :style="[{
		'--colorPrimary': settings.colorPrimary,
		'--colorSecondary': settings.colorSecondary,
		'--colorTertiary': settings.colorTertiary
	}]">
		<router-view/>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { shouldElementRender } from '@/helpers/breakpoint';
	import store from '@/store/index';
	import Vue from 'vue';

	export default {
		name: 'App',
		data: () => ({
			delay: 250,
			timeout: null,
			breakpoint: true
		}),
		computed: {
			...mapState({
				settings: (state) => state.settings
			})
		},
		watch: {
			$route (to, from) {
				this.resizeIframe();
			}
		},
		methods: {
			initiateSceenUpdate () {
				if (this.timeout) {
					clearTimeout(this.timeout);
				}

				this.timeout = setTimeout(() => {
					this.updateMapVisibility();
					if (shouldElementRender(['none', 'tiny']) === true) {
						this.toggleLocationsList(true);
					}

					this.timeout = null;
				}, this.delay);
			},
			...mapActions({
				updateMapVisibility: 'userInterface/updateMapVisibility',
				toggleLocationsList: 'userInterface/toggleLocationsList',
				resizeIframe: 'resizeIframe'
			})
		},
		created () {
			this.updateMapVisibility();

			window.addEventListener('resize', this.initiateSceenUpdate);

			store.watch((state) => state, () => {
				Vue.nextTick(() => {
					this.resizeIframe();
				});
			}, { deep: true });
		}
	};
</script>

<style lang="scss" scoped>
	.app {
		width: 100%;
	}
</style>

<style lang="scss">
	// Default styles (reset)

	* {
		margin: 0;
		padding: 0;
	}

		// Credit: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
	* {
		&,
		&::before,
		&::after {
			box-sizing: inherit;
		}
	}

	html {
		// Prevent scrolling in app, to give an app like experience
		// position: fixed;
		// top: 0;
		// right: 0;
		// bottom: 0;
		// left: 0;
		// overflow: hidden;

		color: $color-dark;
		font-size: 62.5%; // Now 1 rem = 10px
		font-family: sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-text-size-adjust: none;
		text-size-adjust: none;
		box-sizing: border-box;

		// eslint-disable-next-line max-len
		// Info: https://bitbucket.org/netvlies/nvs-frontend/pull-requests/15/_basescss-edited-online-with-bitbucket/diff
		text-decoration-skip-ink: auto;

		// Info: https://developer.mozilla.org/en/docs/Web/CSS/scroll-behavior
		scroll-behavior: smooth;

		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	body {
		// Fix scrollbars: http://msdn.microsoft.com/en-us/library/ie/hh771902(v=vs.85).aspx
		-ms-overflow-style: scrollbar;
		font-size: 1.6rem;
	}

		// html,
		// body {
		// 	overflow: hidden;
		// }

	// Fixes bug on MS touch/tablet
	@-ms-viewport { /* stylelint-disable-line */
		width: device-width;
	}

	:focus:hover {
		outline-width: 0;
	}

	img {
		// All normal images shoud be no greater than their container
		// and be auto height
		max-width: 100%;
		height: auto;
		vertical-align: top;
	}

	iframe {
		margin: 0;
		border: 0;
	}

	main,
	aside,
	section,
	picture {
		display: block;
	}

	ul,
	ol {
		list-style: none;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		min-width: 0;
		max-width: 100%;
		color: inherit;
		font: inherit;
		vertical-align: baseline;
	}

	hr {
		height: 0;
		margin: 6rem 0;
		border: none;
		border-top: 1px solid #979797;
	}

	table {
		width: 100%;
		table-layout: fixed;
		border-collapse: collapse;
	}


	::placeholder {
		color: $color-grey-light;
		font-size: inherit;
		font-style: italic;
		font-weight: lighter;
	}
</style>
