/**
 * Capitalizes a given string, makes the rest lowercase.
 * Comes in handy for city notations etc
 * @param {string} string
 *
 * @returns {string} result
 */
export function capitalize (string) {
	if (string.indexOf(' ') >= 0) {
		const result = string.split(' ');

		return `${result[0].charAt(0).toUpperCase() + result[0].slice(1).toLowerCase()} 
				${result[1].charAt(0).toUpperCase() + result[1].slice(1).toLowerCase()}`;
	}
	if (string.indexOf('-') >= 0) {
		const result = string.split('-');

		// eslint-disable-next-line
		return `${result[0].charAt(0).toUpperCase() + result[0].slice(1).toLowerCase()}-${result[1].charAt(0).toUpperCase() + result[1].slice(1).toLowerCase()}`;
	}

	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
