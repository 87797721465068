<template>
	<label
		:class="['checkbox', { 'checkbox--checked': checked === true }]"
		:for="name">
		<input
			class="checkbox__input visually-hidden"
			type="checkbox"
			:value="checked"
			:id="name"
			:name="name"
			@change="update" />
		<span class="checkbox__label">{{ label }}</span>
	</label>
</template>

<script>
	import Vue from 'vue';

	export default {
		name: 'Checkbox',
		data: () => ({
			checked: false
		}),
		props: {
			name: String,
			change: Function,
			label: String,
			initialChecked: Boolean
		},
		methods: {
			update (evt) {
				this.checked = evt.target.checked;

				Vue.nextTick().then(() => {
					if (this.change) {
						this.change(evt);
					}
				});
			}
		},
		created () {
			if (typeof this.initialChecked !== 'undefined') {
				this.checked = this.initialChecked;
			}
		}
	};
</script>


<style lang="scss" scoped>
	.checkbox {
		position: relative;
		display: block;
		padding: 0.5rem 5rem 0.5rem 3.5rem;

		&[for] {
			cursor: pointer;
		}

		& + & {
			margin-top: 1.5rem;
		}

		&::before,
		&::after {
			position: absolute;
			left: 0;
			content: '';
		}

		&::before {
			top: 0.2rem;
			width: 2.2rem;
			height: 2.2rem;
			background: $color-light;
			border-radius: 0.3rem;
		}

		&::after {
			display: none;
			top: -0.4rem;
			left: -0.3rem;
			width: 1.5rem;
			height: 2.5rem;
			border-right: 0.5rem solid $color-dark;
			border-bottom: 0.5rem solid $color-dark;
			transform: rotate(45deg);
			transform-origin: right bottom;
		}

		&--checked::after {
			display: block;
		}
	}
</style>
