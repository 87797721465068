<template>
	<li :class="`location-list-item location-list-item--${location.category}`">
		<div class="location-list-item__content">
			<h3><router-link :to="{ name: 'detail', params: { id: location.id }}">{{ location.name }}</router-link></h3>
			<address>
				{{ location.address.street }}, {{ location.address.postalCode }}, {{ location.address.city | city }} <br>
				Tel: <a v-if="location.phone" :href="`tel:${location.phone}`">{{ location.phone }}</a>
			</address>

			<WaitingTimes
				class="space--t1"
				:category="location.category"
				:unknown="location.waitingTimeUnknown"
				:averageRegistration="location.waitingTimeAverageRegistration"
				:averageTreatment="location.waitingTimeAverageTreatment" />
		</div>
		<div
			class="location-list-item__map"
			v-if="showMap === true">
			<router-link :to="{ name: 'detail', params: { id: location.id }}"><img :src="staticMapImage"/></router-link>
		</div>
	</li>
</template>

<script>
	import { capitalize } from '@/helpers/text';
	import WaitingTimes from '@/components/WaitingTimes';

	export default {
		name: 'LocationsListItem',
		components: { WaitingTimes },
		props: {
			location: {
				type: Object
			},
			apiKey: {
				type: String,
				default: () => process.env.VUE_APP_MAPS_API_KEY
			},
			gMapBreakpoints: {
				type: Array
			},
			defaultItemZoom: {
				type: String,
				default: () => process.env.VUE_APP_MAPS_ZOOM_DEFAULT_CARD
			}
		},
		filters: {
			city: (value) => capitalize(value)
		},
		computed: {
			showMap () {
				return this.$store.state.userInterface.map.visible;
			},
			staticMapImage: (props) => {
				if (props) {
					return `https://maps.googleapis.com/maps/api/staticmap\
						?center=${props.location.latitude},${props.location.longitude}
						&zoom=${props.defaultItemZoom}
						&size=200x200
						&markers=${props.location.latitude},${props.location.longitude}
						&key=${props.apiKey}`;
				}

				return '';
			}
		}
	};
</script>

<style lang="scss" scoped>
	$module: 'location-list-item';

	.#{$module} {
		display: flex;
		flex: 1;
		margin-bottom: 1rem;
		border-width: 1px;
		border-left-width: 1rem;
		border-style: solid;

		&__map {
			display: none;
		}

		&__content {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			flex: 1;
			justify-content: flex-start;
			padding: 1rem;
			text-align: left;
			line-height: 1.5;
			color: $color-dark;
			font-size: 1.5rem;

			> * {
				width: 100%;
			}

			h3 {
				margin: 0 0 0.875rem 0;
				font-size: 1.8rem;
				line-height: 1.875rem;

				a {
					color: inherit;
					text-decoration: none;
				}
			}

			address {
				font-style: normal;

				a {
					color: $color-dark;
				}
			}
		}

		&--huisarts_en_praktijkondersteuner {
			border-color: $color-huisarts-en-praktijkondersteuner;
		}

		&--zorgaanbieder_ggz {
			border-color: $color-zorgaanbieder-ggz;
		}

		&--zorgaanbieder_somatiek {
			border-color: $color-zorgaanbieder-somatiek;
		}

		@include respond-to(small) {
			min-height: 15rem;

			&__map {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 15rem;
				height: 15rem;
				height: 100%;
				background: lightgrey;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>
