<template>
	<form
		ref="form"
		:class="['filters', { 'filters--loading': loading }]"
		v-if="(map.visible && filters.visible) || !map.visible">
		<div class="filters__header">
			<Heading type="4" color="dark" classes="space--b1">Naar wat bent u op zoek?</Heading>
			<Input
				fieldType="text-with-button"
				icon="search"
				name="query"
				:readonly="loading"
				:value="filters.active.query"
				placeholder="Praktijknaam, behandeling..."
				:change="updateFilters"
				:submit="initiateFilters" />
		</div>

		<div class="filters__switchArea">
			<Heading type="4" color="light">Help mij (verder) zoeken</Heading>
			<button
				type="button"
				v-if="!map.visible"
				class="filters__toggle button button--reset"
				@click="toggleFilters">
				<SvgIcon v-show="expanded" icon="angle-up"/>
				<SvgIcon v-show="!expanded" icon="angle"/>
			</button>
		</div>

		<div class="filters__body" v-if="expanded">
			<p class="filters__category">Naar welk type zorg bent u op zoek?</p>

			<div class="space--b2">
				<RadioGroup
					name="category"
					:options="getCategoryOptions()"
					:checked="filters.active.category"
					:change="updateFilters" />
			</div>

			<div v-if="selectsVisible()" class="space--b2">
				<p class="filters__category">Type zorgaanbieder</p>

				<Select
					name="partner_type"
					:change="updateFilters"
					:initialSelected="filters.active.partner_type"
					:options="getPartnerTypes()" />

				<p class="filters__category space--t2">Doelgroep</p>

				<Select
					name="target_audience"
					:change="updateFilters"
					:initialSelected="filters.active.target_audience"
					:disabled="isEmpty(getTargetAudiences())"
					:options="getTargetAudiences()" />

				<template v-if="user">
					<p class="filters__category space--t2">Consulteren</p>

					<Checkbox
						name="consultation_partner"
						:change="updateFilters"
						:initialChecked="filters.active.consultation_partner"
						label="Toon consultatiepartners" />
				</template>
			</div>

			<p class="filters__category">Naar welke plaats bent u op zoek?</p>

			<div class="space--b2">
				<Input
					fieldType="text-with-button"
					buttonText="Zoek"
					name="place"
					placeholder="Bv: Amsterdam, Groningen..."
					:value="filters.active.placeName ? filters.active.placeName : null"
					:readonly="loading"
					:change="updateFilters"
					:submit="initiateFilters" />
			</div>

			<p class="filters__category">Binnen een straal van?</p>

			<div class="space--b2">
				<Input
					type="number"
					name="radius"
					placeholder="25"
					withText="km"
					required
					:min="1"
					:max="200"
					:value="filters.active.radius"
					:readonly="loading"
					:change="updateFilters"
					:submit="initiateFilters" />
			</div>

			<div class="filters__actions">
				<Button :click="initiateFilters" classes="button--3" type="submit">Toon resultaat</Button>
				<button
					type="reset"
					class="filters__reset button--reset"
					@click.prevent="resetFilters">
					Wis alle filters
				</button>
			</div>
		</div>
	</form>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import Input from '@/components/Input';
	import Heading from '@/components/Heading';
	import SvgIcon from '@/components/SvgIcon';
	import Button from '@/components/Button';
	import RadioGroup from '@/components/RadioGroup';
	import Select from '@/components/Select';
	import Checkbox from '@/components/Checkbox';

	import isEmpty from '@/helpers/isEmpty';

	export default {
		name: 'Filters',
		components: { Input, Heading, SvgIcon, Button, RadioGroup, Select, Checkbox },
		inject: [
			'googleApi',
			'zoomToFitResults',
			'zoomToFitBounds',
			'closeInfoWindow'
		],
		props: {
			expanded: Boolean
		},
		data: () => ({
			env: process.env,
			loading: false,
			placeName: null,
			geocoder: null
		}),
		computed: {
			mapApi () {
				return this.googleApi();
			},
			...mapState({
				locations: (state) => state.locations.locations,
				filters: (state) => state.userInterface.filters,
				map: (state) => state.userInterface.map,
				settings: (state) => state.settings,
				user: (state) => state.user.user
			})
		},
		methods: {
			isEmpty,
			...mapActions({
				toggleFilters: 'userInterface/toggleFilters',
				saveFilters: 'userInterface/saveFilters',
				savePreviousRadius: 'userInterface/savePreviousRadius',
				requestLocations: 'locations/requestLocations',
				updateCenter: 'settings/updateCenter'
			}),
			setLoadingState (loading) {
				if (loading) {
					this.loading = true;
				} else {
					this.loading = false;

					if (!this.map.visible && this.expanded && this.locations.length) {
						this.toggleFilters();
					}

					if (this.map.visible && this.locations.length) {
						this.zoomToFitResults();
						this.closeInfoWindow();
					}
				}
			},
			selectsVisible () {
				const activeCategory = this.filters.active.category;

				return (activeCategory && activeCategory !== 'huisarts_en_praktijkondersteuner');
			},
			getPartnerTypes () {
				if (this.selectsVisible()) {
					return this.settings.filters.partner_types[this.filters.active.category];
				}

				return {};
			},
			getTargetAudiences () {
				if (this.selectsVisible()) {
					return this.settings.filters.target_audiences[this.filters.active.category];
				}

				return {};
			},
			getCategoryOptions () {
				const catData = [
					{
						id: 'huisarts_en_praktijkondersteuner',
						label: 'Huisarts & praktijkondersteuner',
						img: `${this.env.BASE_URL}/img/category-2.svg`
					},
					{
						id: 'zorgaanbieder_ggz',
						label: 'Zorgaanbieder GGZ',
						img: `${this.env.BASE_URL}/img/category-1.svg`
					},
					{
						id: 'zorgaanbieder_somatiek',
						label: 'Zorgaanbieder somatiek',
						img: `${this.env.BASE_URL}/img/category-3.svg`
					}
				];

				return this.settings.categories.map((category) => catData.filter((item) => item.id === category)[0]);
			},
			updateFilters (evt) {
				if (evt.target.name === 'place') {
					this.placeName = null;

					this.saveFilters({
						...this.filters.active,
						...{
							placeName: null,
							latitude: null,
							longitude: null
						}
					});
				}

				if (evt.target.name === 'consultation_partner' && evt.target.value === 'false') {
					const filters = this.filters.active;

					delete this.filters.active.consultation_partner;

					this.saveFilters(filters);

					return;
				}

				if (evt.target.name === 'category' && evt.target.value === 'huisarts_en_praktijkondersteuner') {
					const filters = this.filters.active;
					const filter = { [evt.target.name]: evt.target.value };

					delete this.filters.active.partner_type;
					delete this.filters.active.target_audience;
					delete this.filters.active.consultation_partner;

					this.saveFilters({ ...filters, ...filter });

					return;
				}

				const value = (evt.target.value === 'true' || evt.target.value === 'false')
					? evt.target.value === 'true'
					: evt.target.value;

				const filter = { [evt.target.name]: value };

				this.saveFilters({ ...this.filters.active, ...filter });
			},
			initiateFilters (evt) {
				const formValid = this.$refs.form.checkValidity();

				if (!formValid) {
					if (this.$refs.form.reportValidity) {
						this.$refs.form.reportValidity();
					}

					return;
				}

				this.setLoadingState(true);

				if (evt.preventDefault) {
					evt.preventDefault();
				}

				if (this.filters.active.place) {
					if (!this.geocoder) {
						this.geocoder = new this.mapApi.maps.Geocoder();
					}

					this.geocoder.geocode({
						address: this.filters.active.place,
						region: 'NL'
					}, (results, status) => {
						if (status === 'OK') {
							const { location } = results[0].geometry;

							const filter = {
								placeName: results[0].formatted_address,
								latitude: location.lat(),
								longitude: location.lng()
							};

							this.saveFilters({ ...this.filters.active, ...filter });
						}

						this.executeFilter();
					});
				} else {
					this.executeFilter();
				}
			},
			executeFilter () {
				this.requestLocations()
					.then((result) => {
						this.savePreviousRadius(this.filters.active.radius);
						this.setLoadingState(false);
					}
				);
			},
			resetFilters (input) {
				this.setLoadingState(true);

				this.saveFilters({});

				this.placeName = null;

				this.executeFilter();
			}
		}
	};
</script>

<style lang="scss" scoped>
	$module: 'filters';

	.#{$module} {
		overflow-y: auto;
		max-height: 100%;

		&--loading {
			pointer-events: none;
			opacity: 0.8;
		}

		&__header {
			background: $color-light;
			padding: 2rem 3rem;
		}

		&__title {
			color: $color-dark;
		}

		&__switchArea {
			display: flex;
			flex-flow: row nowrap;
			padding: 1.5rem 3rem;
			color: $color-light;
			@include themeColor(background, colorPrimary);

			.heading {
				flex: 1 0 auto;
			}
		}

		&__category {
			font-size: 1.6rem;
			margin-bottom: 0.5em;
		}

		&__toggle {
			font-size: 2rem;
		}

		&__actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__reset {
			text-decoration: underline;
		}

		&__body {
			padding: 0 3rem 2rem;
			color: $color-light;
		}
	}
</style>
