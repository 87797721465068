import * as actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { shouldElementRender } from '@/helpers/breakpoint';

const state = {
	locationsList: {
		visible: shouldElementRender(['none', 'tiny'])
	},
	filters: {
		visible: shouldElementRender(['small', 'medium', 'large', 'huge']),
		active: window.appConfig.active_filters || {},
		previousRadius: window.appConfig.active_filters.radius
	},
	map: {
		visible: false
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
