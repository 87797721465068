<template>
	<aside class="sidebar">
		<div class="sidebar__header">
			<Button
				:click="toggleFilters"
				class="button sidebar__toggle-btn"
				v-if="map.visible && !filters.visible">
				<svg-icon icon="filter"></svg-icon>
			</Button>

			<Heading type="3" v-if="(map.visible && filters.visible) || !map.visible">
				{{ locations.length }} resultaten
				<small v-if="filters.previousRadius">binnen {{ filters.previousRadius }} km</small>
			</Heading>

			<Button
				v-show="map.visible && filters.visible"
				class="button--4 button--close"
				:click="toggleFilters">
				<SvgIcon icon="cross" />
			</button>
		</div>
		<filters :expanded="filters.visible"></filters>
	</aside>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import Button from '@/components/Button';
	import Filters from '@/components/Filters';
	import Heading from '@/components/Heading';
	import { scrollToElement } from '@/helpers/scroll';

	export default {
		name: 'SideBar',
		components: { Button, Filters, Heading },
		computed: {
			...mapState({
				locations: (state) => state.locations.locations,
				filters: (state) => state.userInterface.filters,
				map: (state) => state.userInterface.map
			})
		},
		methods: {
			...mapActions({
				toggleFilters: 'userInterface/toggleFilters'
			}),
			scrollToResults () {
				scrollToElement('.location-list-wrapper', -70);
			}
		}
	};
</script>


<style lang="scss" scoped>
	$module: 'sidebar';

	.#{$module} {
		@include themeColor(background, colorPrimary);
		position: relative;
		max-height: 100%;
		z-index: 10;
		padding-top: 6rem;
		-ms-grid-row: 1;
		-ms-grid-column: 2;
		grid-area: sidebar;
		user-select: none;

		&__header {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			height: 6rem;
			display: flex;
			align-items: center;
			padding: 0 2rem;
			@include themeColor(background, colorPrimary);
			text-align: center;
			z-index: 10;

			h3 {
				flex: 1;
				color: $color-light;
			}

			.button--close {
				width: 38px;
				height: 38px;
				color: $color-light;
				border-color: $color-light;
			}
		}

		&__toggle-btn {
			padding: 0;
			color: $color-light;
			height: 4.7rem;

			.svg-icon {
				width: 1.8em;
				height: 1.8em;
			}
		}
	}

	@include respond-to(small) {
		.#{$module} {
			display: flex;
			flex-flow: column nowrap;
			padding-top: 0;
			box-shadow: 0 0 2rem rgba($color-dark, 0.15);
			overflow: hidden;

			&__header {
				position: relative;
				height: 8rem;

				h3 {
					text-align: left;
					padding-right: 2rem;
				}
			}
		}
	}
</style>
