<template>
	<main :class="['overview', { 'overview--filtersVisible': filters.visible }]">
		<SideBar />

		<div class="overview__content">
			<google-map
				v-if="gmapApi && markers"
				v-show="map.visible"
				ref="map"
				:options="{ gestureHandling: 'greedy' }"
				:center="{ lat: settings.latitude, lng: settings.longitude }"
				:zoom="8">
				<google-map-cluster
					ref="cluster"
					zoomOnClick
					:maxZoom="20"
					:minimumClusterSize="3">

					<GoogleMapInfoWindow :infoWindow="infoWindow" :closeClick="closeInfoWindow" />

					<google-map-marker
							v-for="marker in markers"
							:position="marker.position"
							:key="marker.key"
							:icon="marker.icon"
							:clickable="true"
							@click="openInfoWindow(marker.key)"
					></google-map-marker>
				</google-map-cluster>
			</google-map>

			<Button
				v-if="map.visible && locations.length"
				class="button--1 button--shadow toggle-list-view"
				:click="toggleLocationsList">
				Toon lijst
			</Button>

			<div v-if="locationsListVisible && locations.length" class="list-wrapper">
				<SortBar :shadow="locationListScrolled" />
				<LocationsList @scrolledChange="handleScrollChange" :locations="locations"></LocationsList>
			</div>
		</div>

		<UserBar />
	</main>
</template>

<script>
	import Vue from 'vue';
	import { mapState, mapActions } from 'vuex';
	import { gmapApi } from 'vue2-google-maps';
	import SideBar from '@/components/SideBar';
	import LocationsList from '@/components/LocationsList';
	import SortBar from '@/components/SortBar';
	import UserBar from '@/components/UserBar';
	import Button from '@/components/Button';
	import GoogleMapInfoWindow from '@/components/GoogleMapInfoWindow';

	export default {
		name: 'Overview',
		components: { SideBar, LocationsList, SortBar, UserBar, Button, GoogleMapInfoWindow },
		provide () {
			return {
				googleApi: () => this.google,
				zoomToFitResults: this.zoomToFitResults,
				zoomToFitBounds: this.zoomToFitBounds,
				closeInfoWindow: this.closeInfoWindow
			};
		},
		data: () => ({
			gmapApi,
			locationListScrolled: false,
			infoWindow: {
				id: 0,
				title: '',
				content: {
					address: {
						street: '',
						city: ''
					}
				},
				opened: false,
				position: {
					lat: 0,
					lng: 0
				},
				options: {
					pixelOffset: {
						width: 0,
						height: -35
					}
				}
			}
		}),
		computed: {
			google: gmapApi,
			...mapState({
				locations: (state) => state.locations.locations,
				locationsListVisible: 'userInterface/locationsListVisible',
				map: (state) => state.userInterface.map,
				filters: (state) => state.userInterface.filters,
				settings: (state) => state.settings
			}),
			locationsListVisible () {
				return this.$store.state.userInterface.locationsList.visible;
			},
			markers () {
				if (!Array.isArray(this.locations) || !this.google) {
					return [];
				}

				const sameLocationsStorage = {};

				const markers = this.locations.map((location) => {
					// SAME-LOCATION DISPLACEMENT FUNCTIONS
					// Support ticket https://netvlies.zendesk.com/agent/tickets/16749

					// Find other companies with the exact same coordinates
					// (locations with the same coordinates cannot be grouped by Maps)
					const sameLocations = this.locations.filter((other) =>
						other.latitude === location.latitude && other.longitude === location.longitude
					);

					// If there are indeed locations with the same coordinates
					if (sameLocations.length > 1) {
						// Store all the locations with the same coordinates outside of this .map()
						// under a unique key that is equal to their longitude for later reference
						if (!sameLocationsStorage[location.longitude]) {
							sameLocationsStorage[location.longitude] = sameLocations;
						}

						// To show all markers correctly, calculate a certain deviation from the
						// index of every item (0, 1, 2, ...) devided by a large number and add to
						// the latitude to alter the location a little.
						const deviation = sameLocationsStorage[location.longitude].indexOf(location) / 50000;

						// The new location is the old location + the calculated deviation
						// We alter the latitude prop, because later the infoWindow will use the
						// same coordinates to place itself above the marker.
						// location.latitude = location.latitude + deviation;
						Vue.set(location, 'latitude', location.latitude + deviation);
					}

					return {
						key: location.id,
						position: {
							lat: location.latitude,
							lng: location.longitude
						},
						icon: {
							scaledSize: new this.google.maps.Size(46, 52),
							url: this.mapIcon(location.category)
						}
					};
				});

				return markers;
			}
		},
		methods: {
			mapIcon (type) {
				/* eslint-disable vue/script-indent */
				switch (type) {
					case 'huisarts_en_praktijkondersteuner':
						return `${process.env.BASE_URL}img/location-category-2.svg`;
					case 'zorgaanbieder_somatiek':
						return `${process.env.BASE_URL}img/location-category-3.svg`;
					case 'zorgaanbieder_ggz':
					default:
						return `${process.env.BASE_URL}img/location-category-1.svg`;
				}
				/* eslint-enable */
			},
			...mapActions({
				toggleLocationsList: 'userInterface/toggleLocationsList',
				resizeIframe: 'resizeIframe',
				requestLocations: 'locations/requestLocations'
			}),
			openInfoWindow (id) {
				const location = this.locations.find((item) => item.id === id);

				if (location) {
					this.infoWindow.content = location;
					this.infoWindow.position = {
						lat: location.latitude,
						lng: location.longitude
					};

					this.infoWindow.id = location.id;
					this.infoWindow.title = location.name;

					this.infoWindow.opened = true;
				}
			},
			closeInfoWindow () {
				this.infoWindow.opened = false;
			},
			zoomToFitResults () {
				const bounds = new this.google.maps.LatLngBounds();

				this.markers.forEach((marker) =>
					bounds.extend(marker.position)
				);

				this.$refs.map.fitBounds(bounds);

				this.google.maps.event.addListener(this.$refs.map.$mapObject, 'idle', () => {
					this.$refs.map.$forceUpdate();
					this.$refs.cluster.$forceUpdate();
				});
			},
			zoomToFitBounds (bounds) {
				this.$refs.map.fitBounds(bounds);
			},
			handleScrollChange (scrolled) {
				this.locationListScrolled = scrolled;
			}
		},
		created () {
			this.loading = true;

			if (this.locations.length) {
				this.loading = false;

				if (this.map.visible) {
					Vue.nextTick().then(() => {
						this.zoomToFitResults();
					});
				}
			} else {
				this.requestLocations().then((result) => {
					if (this.map.visible && result.length) {
						this.loading = false;

						Vue.nextTick().then(() => {
							this.zoomToFitResults();
						});
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	$module: 'overview';

	.#{$module} {
		&__content {
			position: relative;
			display: flex;
			flex: 1;

			.toggle-list-view {
				position: absolute;
				top: 2rem;
				right: 2rem;
				z-index: 2;
			}

			.list-wrapper {
				padding: 0;
				width: 100%;
				background: $color-light;
			}
		}
	}

	.gm-fullscreen-control,
	.gm-style-mtc {
		display: none;
	}

	.gm-style-iw > div {
		overflow: hidden !important;
	}

	@include respond-to(small) {
		.overview {
			min-height: 75rem;
			overflow: hidden;
		}

		.vue-map-container,
		.vue-map {
			position: absolute !important;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.#{$module} {
			display: grid;
			grid-template:
				"map sidebar" 1fr
				"userbar userbar" 8rem /
				1fr auto;

			&__content {
				display: grid;
				-ms-grid-row: 1;
				-ms-grid-column: 1;
				grid-area: map;
			}

			&--filtersVisible {
				grid-template-columns: 1fr 40%;
			}

			.nonExsistentElementName {
				grid-area: sidebar;
				grid-area: userbar;
			}

			.list-wrapper {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 3;
				display: flex;
				flex-direction: column;
				width: auto;
				box-shadow: 0 0 3rem rgba(0, 0, 0, 0.15);
			}
		}
	}

	@include respond-to(medium) {
		.#{$module} {
			&--filtersVisible {
				grid-template-columns: 1fr 35%;
			}
		}
	}

	@include respond-to(large) {
		.#{$module} {
			&--filtersVisible {
				grid-template-columns: 1fr 30%;
			}
		}
	}
</style>
