import Vue from 'vue';
import Router from 'vue-router';
import Overview from './views/Overview.vue';
// import Detail from './views/Detail.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'home',
			component: Overview
		},
		{
			path: '/detail/:id',
			name: 'detail',
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			props: true,
			component: () =>
				import(/* webpackChunkName: 'detail' */ './views/Detail.vue')
			// component: Detail
		}
	]
});
