import PopupWindow from '@/helpers/popupWindow';

export const updateCenter = ({ commit }, newState) => {
	commit('UPDATE_CENTER', newState);
};

export const openLoginLink = ({ state }) => {
	// Note that`location.reload()`will NOT work because the server
	// doesn't support a frontend router like vue-router. Opening the
	// /detail url will result in a 404, thus we "reload" by going
	// to the homepage of the app.
	const onClose = () => window.location.href = '/';
	const popupWindow = new PopupWindow();

	popupWindow.open('/connect/salesforce', onClose);
};
