import Vue from 'vue';

export const mutations = {
	UPDATE_CENTER (state, coordinate) {
		Vue.set(state, 'latitude', coordinate.lat());
		Vue.set(state, 'longitude', coordinate.lng());
	}
};

export default mutations;
