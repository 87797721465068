import API from '@/api';
import orderService from '@/helpers/orderService';
import store from '../../';

export const requestLocations = ({ commit, rootState }, filters) => new Promise((resolve, reject) => {
	if (typeof filters !== 'object') {
		filters = store.state.userInterface.filters.active;
	}

	if (!filters.latitude) {
		filters.latitude = store.state.settings.latitude;
	}

	if (!filters.longitude) {
		filters.longitude = store.state.settings.longitude;
	}

	if (!filters.radius) {
		filters.radius = store.state.settings.radius;
	}

	/* eslint-disable-next-line */
	filters.exclude_categories = store.state.settings.excludeCategories;

	const queries = Object.keys(filters)
		.filter((item) => {
			if (!filters.hasOwnProperty(item)) {
				return false;
			}

			// The `exclude_categories` filter may exist and be an empty string
			// This is the only filter that is allowed to be empty
			if (item === 'exclude_categories') {
				return filters[item] !== null;
			}

			// All other filter have to be truthy in order to be used and passed
			// to the API (https://developer.mozilla.org/en-US/docs/Glossary/Truthy)
			return filters[item];
		})
		.map((item) => `${item}=${filters[item]}`)
		.join('&');

	return API.get(`${window.appConfig.api_locations_search_url}?${queries}`)
		.then((response) => {
			const sortingType = rootState.locations.listSorting;

			if (response.data) {
				// Set initial result with initial sortingType, which should be name
				commit('SET_LOCATIONS', orderService.orderItemsByKey(response.data, sortingType, true));
				resolve(response.data);
			}
		})
		.catch((error) => {
			reject(error);
		});
});

export const requestLocation = ({ commit, rootState }, id) => new Promise((resolve, reject) => {
	if (typeof id !== 'string') {
		throw Error('No `id` parameter given for request `requestLocation`');
	}

	// eslint-disable-next-line
	return API.get(`/api/locations/${id}`)
		.then((response) => {
			if (response.data) {
				resolve(response.data);
			}
		})
		.catch((error) => {
			reject(error);
		});
});

export const updateListSorting = ({ commit, rootState }, sortingType) => {
	commit('SET_LIST_SORTING', sortingType);
	commit('SET_LOCATIONS', orderService.orderItemsByKey(rootState.locations.locations, sortingType, true));
};
