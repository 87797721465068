<template>
	<svg
		:class="`svg-icon svg-icon--${icon} ${classes}`"
		:title="`${icon} icon`"
		:aria-label="`${icon} icon`"
		width="1em"
		height="1em">
		<use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="`/build/app/icons.svg#icon__${icon}`"></use>
	</svg>
</template>

<script>
	export default {
		name: 'SvgIcon',
		props: {
			classes: {
				type: String,
				default: ''
			},
			/**
			 * Name of the icon to render
			 */
			icon: {
				type: String,
				default: 'arrow',
				validator: (type) => [
					'add',
					'angle',
					'angle-up',
					'cancel',
					'cross',
					'filter',
					'phone',
					'email',
					'computer',
					'target',
					'search'
				].indexOf(type) !== -1
			}
		}
	};
</script>

<style lang="scss">
$module: 'svg-icon';

.#{$module} {
	width: 1em;
	height: 1em;
	margin-top: -.2rem;
	margin-bottom: -.2rem;
	line-height: 1;
	vertical-align: middle;
	fill: currentColor;

	use {
		fill: currentColor;
	}
}
</style>
