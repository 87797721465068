<template>
	<select
		:class="['select', { 'select--active': value !== null, 'select--disabled': disabled }]"
		:id="name"
		:name="name"
		:disabled="disabled"
		@input="update"
		@change="update">
		<option value="" :selected="value === null">{{ disabled ? disabledText : 'Allen' }}</option>
		<option
			v-if="Array.isArray(options)"
			v-for="(option, key) in options"
			:selected="value === option"
			:value="option"
			:key="key">
			{{ option }}
		</option>
		<option
			v-if="isObject(options)"
			v-for="(item, key) in Object.keys(options).filter(item => options.hasOwnProperty(item))"
			:selected="value === item"
			:value="item"
			:key="key">
			{{ options[item] }}
		</option>
	</select>
</template>

<script>
	import isObject from 'is-plain-obj';

	export default {
		name: 'Select',
		data: () => ({
			value: null,
			isObject
		}),
		props: {
			name: String,
			options: [Object, Array],
			change: Function,
			initialSelected: String,
			disabled: Boolean,
			disabledText: {
				type: String,
				default: 'Geen opties'
			}
		},
		methods: {
			update (evt) {
				this.value = evt.target.value;

				if (this.change) {
					this.change(evt);
				}
			}
		},
		created () {
			if (this.initialSelected) {
				this.value = this.initialSelected;
			}
		}
	};
</script>


<style lang="scss" scoped>
	.select {
		display: block;
		width: 100%;
		background: $color-light url('/build/app/img/select-bg.svg') right 3rem center no-repeat;
		padding: 1em 1.2em;
		border: 1px solid $color-grey-light;
		border-radius: 10rem;

		appearance: none;
		color: $color-grey-light;
		font-style: italic;
		font-weight: lighter;

		&::-ms-expand {
			display: none;
		}

		&--active {
			color: $color-dark;
			font-style: normal;
			font-weight: normal;
		}

		&[disabled] {
			color: darken($color-grey-light, 20);
			background-color: lighten($color-grey-light, 20);
		}
	}
</style>
