class PopupWindow {
	constructor () {
		this.window = null;
		this.timer = null;
	}

	open (url, callback) {
		this.window = window.open(url);
		this.callback = callback;

		this.monitorWindowStatus();
	}

	close () {
		this.timer = null;
		this.window = null;

		cancelAnimationFrame(this.timer);

		if (this.callback) {
			this.callback();
		}
	}

	monitorWindowStatus () {
		if (this.window.closed) {
			this.close();

			return;
		}

		this.timer = requestAnimationFrame(() => this.monitorWindowStatus(this.window));
	}
}

export default PopupWindow;
