<template>
	<div :class="['sort-bar', { 'sort-bar--shadow': shadow }]">
		<div class="sort-bar__controls">
			<p>Sorteer op</p>
			<Button
				:click="() => updateSorting('name')"
				:class="listSorting === 'name' ? 'button--1' : 'button--4'">
				naam
			</Button>
			<Button
				v-if="hasPartnersWithWaitingTime"
				:click="() => updateSorting('waitingTimeAverageRegistration')"
				:class="listSorting === 'waitingTimeAverageRegistration' ? 'button--1' : 'button--4'">
				wachttijd
			</Button>
		</div>
		<Button
			class="button--4 button--close show-from-small"
			:click="toggleLocationsList">
			<svg-icon icon="cross"></svg-icon>
		</button>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import Button from '@/components/Button';
	import SvgIcon from '@/components/SvgIcon';
	import { animateElementScroll } from '@/helpers/scroll';

	export default {
		name: 'SortBar',
		components: { SvgIcon, Button },
		props: {
			shadow: Boolean
		},
		computed: {
			...mapState({
				locations: (state) => state.locations.locations,
				listSorting: (state) => state.locations.listSorting,
				locationsListVisible: (state) => state.locations.locationsListVisible
			}),
			hasPartnersWithWaitingTime () {
				return this.locations.some((location) => location.waitingTimeUnknown === false);
			}
		},
		methods: {
			updateSorting (type) {
				const locationList = document.querySelector('.location-list-wrapper');

				if (type !== this.listSorting) {
					this.updateListSorting(type);
					animateElementScroll(locationList, '', locationList.scrollTop, 0, 300);
				}
			},
			...mapActions({
				toggleLocationsList: 'userInterface/toggleLocationsList',
				updateListSorting: 'locations/updateListSorting'
			})
		}
	};
</script>

<style lang="scss">
	$module: 'sort-bar';

	.#{$module} {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2rem 1rem;

		&__controls {
			text-align: center;

			p {
				margin: 0 0 1.5rem 0;
				color: $color-dark;
			}
		}

		.button--close {
			width: 38px;
			height: 38px;
		}

		&--shadow:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background: linear-gradient($color-dark, rgba($color-dark, 0));
			height: 0.75rem;
			opacity: 0.15;
		}
	}

	@include respond-to(small) {
		.#{$module} {
			justify-content: space-between;
			padding: 2rem;

			&__controls {
				display: flex;
				align-items: center;
				flex: 1;
				padding-right: 1rem;

				p {
					margin: 0 1rem 0 0;
				}
			}

			> * {
				user-select: none;
				box-sizing: border-box;
			}
		}
	}
</style>
