<template>
	<div class="radio-group">
		<label
			v-for="(option, key) in options"
			:key="key"
			:class="['radio', { 'radio--checked': checked === option.id }]"
			:for="option.id">
			<input
				class="radio__input visually-hidden"
				type="radio"
				:id="option.id"
				:value="option.id"
				:checked="checked === option.id"
				:name="name"
				@change="update">
			<span class="radio__label">{{ option.label }}</span>
			<img v-if="option.img" class="radio__img" :src="option.img" />
		</label>
	</div>
</template>

<script>
	export default {
		name: 'RadioGroup',
		props: {
			name: {
				type: String,
				required: true
			},
			options: {
				type: Array,
				required: true
			},
			checked: String,
			change: Function
		},
		methods: {
			update (evt) {
				if (this.change) {
					this.change(evt);
				}
			}
		}
	};
</script>

<style lang="scss">
	.radio {
		position: relative;
		display: block;
		padding: 1rem 5.5rem 1rem 3.5rem;

		&[for] {
			cursor: pointer;
		}

		& + & {
			margin-top: 1.5rem;
		}

		&::before {
			position: absolute;
			top: 0.5em;
			left: 0;
			width: 2.2rem;
			height: 2.2rem;
			background: $color-light;
			border-radius: 50%;
			border: 0.5rem solid $color-light;
			box-shadow: inset -1px 1px 3px rgba($color-dark, 0.4);

			content: '';
		}

		&--checked::before {
			background: $color-dark;
		}

		&__label {
			font-size: 1.6rem;
			line-height: 1.3;
		}

		&__img {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
</style>

